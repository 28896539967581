<template>
  <table-content
    :images="images"
    :list="{}"
    :list-header="listHeader"
  />
</template>
  
<script>
import TableContent from './table/index.vue'
  
export default {
  components: {
    TableContent
  },
  props: {
    images: {
      required: false,
      default: '',
      type: String
    },
    list: {
      required: false,
      default: Object,
      type: Object
    }
  },
  data() {
    return {
      listHeader: [ 
        {
          key: 'supplier_code',
          label: 'Supplier Code',
          custom: 'sort'
        },
        {
          key: 'supplier_name',
          label: 'Supplier Name',
          custom: 'sort'
        },
        {
          key: 'city',
          label: 'City',
          custom: 'sort'
        },
        {
          key: 'product_type',
          label: 'Product',
          custom: 'sort'
        },
        {
          key: 'action',
          label: 'Action'
        }
      ]
    }
  }
}
</script>
  
