<template>
  <div
    class="uk-form-stacked"
  >
    <div
      class="uk-child-width-1-1@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-2@l">
        <label class="uk-form-label">Nama / Kode Supplier</label>
        <div class="uk-form-controls">
          <input
            v-model="search"
            name="search"
            class="uk-input"
            type="text"
            placeholder="Search Nama / Kode Supplier"
            autocomplete="off"
          >
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-5@l">
        <div class="uk-form-controls">
          <label class="uk-form-label">Jenis Produk</label>
          <multiselect 
            v-model="product_types" 
            label="name" 
            name="species" 
            track-by="id" 
            placeholder="All" 
            open-direction="bottom" 
            :options="listProductType"
            :searchable="true"
            :max-height="150"
            :show-no-results="true"
            :show-labels="false"
          >
            <span slot="noResult">Oops! Data tidak ditemukan.</span>
          </multiselect>
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-4@l uk-flex uk-flex-bottom">
        <button
          class="uk-button uk-button-primary uk-margin-small-right uk-padding-small"
          @click.prevent="handleSearch"
        >
          <img
            :src="`${images}/icon/ic_search_white.svg`"
            class="uk-margin-small-right"
            alt=""
            loading="lazy"
          >
          Search
        </button>
        <button
          class="uk-button uk-button-default uk-padding-remove"
          @click.prevent="handleReset"
        >
          Reset Filter
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    images: {
      required: true,
      type: String
    },
    listProductType: {
      required: false,
      default: ()=>[],
      type: Array
    },
    filterSubmit: {
      required: true,
      type: Function
    },
    resetFilter: {
      required: true,
      type: Function
    },
    metaFilter: {
      required: false,
      default: null,
      type: Object
    }
  },
  data() {
    return {
      search: '',
      product_types: ''
    }
  },
  methods: {
    ...mapMutations({
      setMetaFilter: "purchaseOrder/SET_META"
    }),
    handleSearch() {
      this.setMetaFilter({
        ...this.metaFilter,
        search: this.search || null,
        product_types: this.product_types?.id
      })
      this.filterSubmit()
    },
    handleReset() {
      this.product_types = null
      this.search = null
      this.setMetaFilter({
        ...this.metaFilter,
        search: this.search,
        species: this.product_types
      })
      this.resetFilter()
    }
  }
}
</script>
  
  <style scoped>
  .uk-tab > * > a {
    font-size: 14px;
    font-weight: 700;
  }
  </style>
  
