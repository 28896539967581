<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle uk-table-hover"
        aria-describedby="po-farmer-table"
      >
        <table-header :table-header="listHeader" />
        <!-- <template v-if="list">
          <tbody>
            <tr
              v-for="(content, index) in list"
              :key="index"
            >
              <td>{{ content.purchase_order_number || '-' }}</td>
              <td>{{ content.facility.facility_name || '-' }}</td>
              <td>{{ content.weight + ' Kg' || '-' }}</td>
              <td>{{ content.purchase_order_date || '-' }}</td>
              <td>{{ content.purchase_order_received_date|| '-' }}</td>
              <td>
                <div v-if="content.status === 1">
                  <label-status
                    status="active"
                    label="Active"
                    table="true"
                  />
                </div>
                <div v-else-if="content.status === 0">
                  <label-status
                    status="inactive"
                    label="Inactive"
                    table="true"
                  />
                </div>
              </td>
              <td class="action">
                <a
                  uk-tooltip="View"
                  class="label-green"
                  @click.prevent="$router.push({ name: 'PurchaseOrderFarmerDetail', params:{ id: content.id }})"
                >
                  Details
                </a>
              </td>
            </tr>
          </tbody>
        </template> -->
        <template>
          <empty-table
            :colspan="5"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import TableHeader from './header'
import EmptyTable from "@/components/globals/table/EmptyTable"

export default {
  components: {
    TableHeader,
    EmptyTable
  },
  props: {
    images: {
      required: true,
      type: String
    },
    list: {
      required: true,
      type: Object
    },
    listHeader: {
      required: true,
      type: Array
    }
  }
}
</script>

<style scoped>
.disabled {
  cursor: no-drop;
}
</style>
